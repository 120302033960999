import {Fragment, useState} from "react";
import style from './index.module.css'
import { header_comp } from './table_innerComponents/TableComponent'
import arrow_left_icon from "../../assets/images/icons/pagination-arrow-left.svg"
import arrow_right_icon from "../../assets/images/icons/pagination-arrow-right.svg"

const Table = (props) => {
    const { data = header_comp } = props;
    const [more, setMore] = useState({ page: 1, items: 1, more_id: null });
    console.log(data);
    function test(type) {
        const config = {
            header: {
                string: (content) => <th>{content}</th>,
                image: (content) => <th>{content}</th>,
                html: (content) => <th>{content}</th>
            },
            body: {
                string: (content) => <td>{content}</td>,
                html: (content) => <td>{content}</td>,
                image: (content) => <img alt='content' src={content} />,
                number: (content) => <th>{content}</th>
            }
        }

        return config[type]
    }

    const render = (tableType, { content, type = "string" }) => {
        let a = test(tableType);
        return a[type](content);
    }

    return (
        <div className={style.tabel_wrapper}>
            <div className={style.table_scroll}>

                <table className={`${style.table} table_long`}>
                    <thead>
                        <tr>
                            {
                                data.header.map(item => {
                                    console.log(item);
                                    return (
                                        <Fragment key={item.content}>
                                            {render("header", item)}
                                        </Fragment>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.body.map((item, index) => {
                                console.log(item);
                                return (
                                    <tr key={index}>
                                        {
                                            item.map((item, _index) => {
                                                console.log(item);
                                                return (
                                                    <Fragment key={index + _index}>
                                                        {render("body", item)}
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
            <div className={style.table_footer}>
                <div className={style.footer_right}>
                    <button className={style.pagination_conroller}>
                        <img src={arrow_left_icon} alt="arrow-left" />
                    </button>
                    <div className={style.pagination}>
                        <button className={`${style.btn} active`}>1</button>
                        <button className={style.btn}>2</button>
                        <button className={style.btn}>3</button>
                        <button className={style.btn}>4</button>
                        <button className={style.btn}>5</button>
                    </div>
                    <button className={style.pagination_conroller}>
                        <img src={arrow_right_icon} alt="arrow-right" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Table;
