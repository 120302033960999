export function classNames(...clsList) {
    const result = [];

    for (const clsItem of clsList) {
        if (!clsItem) continue;

        if (typeof clsItem === 'object') {
            let _res = Object.entries(clsItem)
                .filter(([_, value]) => Boolean(value))
                .map(([cls]) => cls);

            if (Array.isArray(clsItem)) {
                _res = clsItem.filter((item) => Boolean(item));
            }
            _res.forEach((item) => result.push(item));
        } else {
            result.push(clsItem);
        }
    }

    return result.join(' ');
}
