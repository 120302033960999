import React from "react";
import style from "../../index.module.css"

const Holidays = () => {
    return (
        <div id="sectionFour" className={style.section_card}>
            <div className={`${style.tabel_wrapper} p_0 mt_24`}>
                tut otdihayut
            </div>
        </div>
    )
}

export default Holidays;
