import React from "react";
import style from "../../index.module.css"
import person5 from "../../../../assets/images/img-person-five.png"
import more_icon3 from "../../../../assets/images/icons/icon-more-three.svg"
import info_icon from "../../../../assets/images/icons/icon-information.svg"
import arrow_icon from "../../../../assets/images/icons/arrow-down.svg"
const Employees = () => {
    return (
        <div id="sectionOne" className={style.section_card}>
            <div className={style.cards_wrapper}>
                <div className={style.card}>
                    <div className={style.card_head}>
                        <div className={style.head_left}>
                            <img
                                src={person5}
                                alt="icon user"
                            />
                            <div className={style.texts}>
                                <p>Санжар Ахмедов</p>
                                <span>HR менеджер</span>
                            </div>
                        </div>

                        <label className={style.more_settings}>
                            <input
                                name="more-two"
                                type="checkbox"
                                hidden
                                id="more-btn"
                            />
                            <img
                                src={more_icon3}
                                alt="icon-more"
                            />
                        </label>
                    </div>

                    <div className={style.card_body}>
                        <div className={style.card_body_elem}>
                            <p>Проекты</p>
                            <div className={style.elem_info}>

                                <div className={style.projects_dropdown}>
                                    <p>Akfa Medline</p><img src={arrow_icon} />
                                </div>
                                <div className={style.texts}>
                                    <span>Дата старта:</span>
                                    <p>20.02.2023</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Дата окончания:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Количество задач: </span>
                                    <p>12</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Time log:</span>
                                    <p>12h 30m</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card_body_elem}>
                            <p>Данные</p>
                            <div className={style.elem_info}>
                                <div className={style.texts}>
                                    <span>Зарплаты:</span>
                                    <p>$400</p>
                                </div>
                                <div className={style.texts}>
                                    <span>По часовое:</span>
                                    <p>$12</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Time worked:</span>
                                    <div className={style.worked_time}><p>12h 30m</p><img src={info_icon} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Employees;
