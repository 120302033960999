import React from "react";
import style from "./index.module.css"
import add_icon from "../../assets/images/icons/icon-add.svg"
import more_icon from "../../assets/images/icons/icon-more-two.svg"
import person1 from "../../assets/images/img-person-one.png"
import Table from "../../components/Table";
import Status from "components/Status";
import MoreBtn from "components/MoreBtn";
const Advice = () => {
    let data = {
        header: [
            {
                content: "Предложение"
            },
            {
                content: "Дата"
            },
            {
                content: "Предложитель"
            },
            {
                content: "Статус"
            },
            {
                content: <MoreBtn />,
                type: "html"
            }
        ],
        body: [
            [
                {
                    content: "Что скажете к увеличению зарплаты?"
                },
                {
                    content: "05.05.2023"
                },
                {
                    content: "Жасур Туляганов"
                },
                {
                    content: <Status />,
                    type: "html"
                },
                {
                    content: <MoreBtn />,
                    type: "html"
                }
            ],
            [
                {
                    content: "Что скажете к увеличению зарплаты?"
                },
                {
                    content: "05.05.2023"
                },
                {
                    content: "Жасур Туляганов"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: <MoreBtn />,
                    type: "html"
                }
            ]
        ]
    }
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Ящик для предложений</p>
                <button className={style.add_btn}>
                    <img src={add_icon} alt="icon add" />
                    Создать новый
                </button>
            </div>

            <div className={style.tabel_wrapper}>
                <Table data={data} />
            </div>
        </div>
    )
}

export default Advice;
