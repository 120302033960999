import React from "react";
import style from "../../index.module.css"
import TimeLine from "../../../../components/TimeLine";
const PositionUpgrade = () => {
    return (
        <div>
            <div id="sectionThree" className={style.section_card}>
                <div className={style.container}>
                    <div className={style.upper_container}>
                        <div className={style.upper_container_element}>
                            <p>
                                Планированные часы
                            </p>
                            <div>60 ч</div>
                        </div>
                        <div className={style.upper_container_element}>
                            <p>
                                Планированные часы / time logged
                            </p>
                            <div>24 ч</div>
                        </div>
                        <div className={style.upper_container_element}>
                            <p>
                                Общие время
                            </p>
                            <div>120ч 30 мин </div>
                        </div>
                        <div className={style.upper_container_element}>
                            <p>
                                Общие зарплаты
                            </p>
                            <div>1200$</div>
                        </div>
                        <div className={style.upper_container_element}>
                            <p>
                                Общие время / общие зарплаты
                            </p>
                            <div>120$</div>
                        </div>
                        <div className={style.upper_container_element}>
                            <p>
                                NPV
                            </p>
                            <div>$3,695</div>
                        </div>

                    </div>
                    <div className={style.lower_container}>
                        <TimeLine />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PositionUpgrade;