import React, {useState} from "react";
import styles from "./index.module.css";
import userImg from "../../assets/images/user-img.png";

const PersonalData = () => {

return (
    <div className={styles.options}>
        <div className={styles.options_top}>
            <h3 className={styles.options_top_title}>Личные данные</h3>
        </div>
        <div className={styles.options_info}>
            <div className={styles.options_img}>
                <img src={userImg} alt="user-img"/>
            </div>
            <div className={styles.options_desc}>
                <p className={styles.info_text}>Допустимые форматы изображения: .jpeg, .png, .jpg</p>
                <div className={styles.info_bottom}>
                    <button className={styles.photo_upload}>Загрузить фотографию</button>
                    <button className={styles.edit_btn}>Изменить</button>
                </div>
            </div>
        </div>
        <div className={styles.options_form}>
            <label className={styles.options_label}>
                Имя
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Фамилия
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Email
                <input className={styles.options_input} type="email"/>
            </label>
            <label className={styles.options_label}>
                Номер телефона
                <input className={styles.options_input} type="number"/>
            </label>
            <label className={styles.options_label}>
                Дата рождения
                <input className={styles.options_input} type="data"/>
            </label>
            <label className={styles.options_label}>
                Опыт работы
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Login
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Профессия
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Отдел
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Роль
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                ID компании
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Статус
                <input className={styles.options_input} type="text"/>
            </label>
            <label className={styles.options_label}>
                Telegrma статус
                <input className={styles.options_input} type="text"/>
            </label>
        </div>
        <div className={styles.options_bottom}>
            <button className={styles.edit_form}>Изменить</button>
        </div>
    </div>
 )
}

export default PersonalData;