import React from "react";
import styles from "./index.module.css"
import Login from "../../components/Login";
import Slider from "../../components/Slider";

const Authorization = ({setSuccess}) =>{
    console.log(styles)
    return(
        <div className={styles.wrapper}>
            <Login setSuccess={setSuccess}/>
            <Slider/>
        </div>
    )
}
export default Authorization;