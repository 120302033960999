import React from "react";
import style from "./index.module.css";
import SSDlogo from "../../assets/images/icons/LogoSSD.svg"
import {Link, useLocation} from "react-router-dom";
import {PathRoutes, RouteConfig} from "shared/config/RouteConfig";
import {classNames} from "shared/lib/classNames/classNames";
const SideBar = () => {
    const location = useLocation();

    return (
        <div className={style.wrapper}>
            <aside className={style.sidebar}>
                <Link to={PathRoutes.control}>
                    <img src={SSDlogo} alt="logo" />
                </Link>
                <div className={style.sidebar_menu}>
                    {
                        RouteConfig.map(item => {
                            if(item.text) {
                                return (
                                    <Link
                                        to={item.path}
                                        key={item.text}
                                        className={classNames(style.link, { [style.active]: item.path.length > 1 ? location.pathname.includes(item.path) : location.pathname === item.path })}
                                    >
                                        {item.svgElem}
                                        {item.text}
                                    </Link>
                                )
                            }
                        })
                    }
                </div>
            </aside>
        </div>
    )
}
export default SideBar;
