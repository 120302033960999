import React from "react";
import style from "./index.module.css"
import add_icon from "../../assets/images/icons/icon-add.svg"
import { Tabs } from "../../components/Tabs";
import NewEmployees from "./subEvents/NewEmployees";
import Health from "./subEvents/Health";
import PositionUpgrade from "./subEvents/PositionUpgrade";
import Holidays from "./subEvents/Holidays";

const data = [
    {
        label: "Новые сотрудники",
        key: 0,
        children: <NewEmployees />
    },
    {
        label: "Больничные листы",
        key: 1,
        children: <Health />
    },
    {
        label: "Повышение",
        key: 2,
        children: <PositionUpgrade />
    },
    {
        label: "Отпуска",
        key: 3,
        children: <Holidays />
    }
]

const Events = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Событие</p>
                <button className={style.add_btn}>
                    <img src={add_icon} alt="icon add" />
                    Создать новый
                </button>
            </div>

            <Tabs data={data} />
        </div>
    )
}

export default Events;
