import './App.css';
import React, { useState } from 'react';
import { AppRouter } from './components/Routes/AppRouter';
import Authorization from "./pages/Authorization";


function App() {
    const [success, setSuccess] = useState(false)
    return (
        <>
            {
                success ? <AppRouter /> : <Authorization setSuccess={setSuccess} />
            }
        </>
    )

}

export default App;
