import style from "./index.module.css"
import { Tabs } from "../../components/Tabs";
import down_icon from "../../assets/images/icons/arrow-down.svg"
import Projects from "./subControl/Projects";
import Finances from "./subControl/Finances";
import Employees from "./subControl/Employees";
const Employee = () => {
    const data = [
        {
            label: "Проекты",
            key: 0,
            children: <Projects />
        },
        {
            label: "Сотрудники",
            key: 1,
            children: <Employees />
        },
        {
            label: "Финансы",
            key: 2,
            children: <Finances />
        }
    ]
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Доступ для управления</p>
                <div className={style.project_ddw} onClick="handleOpenPopup()">
                    Akfa Medline
                    <img src={down_icon} alt="icon arrow down" />
                </div>
            </div>

            <div className={style.tabel_wrapper}>
            <Tabs data={data} />
            </div>
        </div>
    )
}

export default Employee;
