import React from "react";
import style from "../../index.module.css"
import Table from "components/Table";
import more_icon from "assets/images/icons/icon-more-two.svg"
import person1 from "assets/images/img-person-one.png"
import MoreBtn from "components/MoreBtn";

const Holidays = () => {
    let data = {
        header: [
            {
                content: "Название проекта"
            },
            {
                content: "Дата старта"
            },
            {
                content: "Дата окончания"
            },
            {
                content: "Сотрудники"
            },
            {
                content: "Бюджет"
            },
            {
                content: "Cost of Resources"
            },
            {
                content: "Fixed cost"
            },
            {
                content: "% complete"
            },
            {
                content: "Earned value"
            },
            {
                content: "planned % complete"
            },
            {
                content: "Planned Value"
            },
            {
                content: "ACWP"
            },
            {
                content: "BCWP"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }
        ],
        body: [
            [{
                content: <div className={style.name}><img src={person1} /> <p>Жасур Туляганов</p></div>,
                type: "html"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: <MoreBtn />,
                type: "html"
            }]
        ]
    }
    return (
        <div id="sectionFour" className={style.section_card}>
            <Table data={data} />
        </div>
    )
}

export default Holidays;
