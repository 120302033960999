const Regulations = () => {
    return (<div id="sectionThree" class="section-card">
        <div class="accordions">
            <div class="accordions-box">
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordions-box">
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion">
                    <div class="accordion__slot">
                        <button class="accordion__button">
                            HR менеджер по международным стандартам.
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="IconArrowDown"
                            />
                        </button>
                    </div>
                    <div class="accordion__slot">
                        <div class="accordion__panel">
                            <p class="text">
                                Рыбатекст используется дизайнерами, проектировщиками и
                                фронтендерами, когда нужно быстро заполнить макеты или
                                прототипы содержимым. <br />
                                <br />
                                Это тестовый контент, который не должен нести никакого
                                смысла, лишь показать наличие самого текста или
                                продемонстрировать типографику в деле.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default Regulations;