export let header_comp = {
    header: [
        {
            content: "Имя сотрудника",
            type: "string"
        },
        {
            content: "Отдел",
            type: "string"
        }
    ],
    body: [
        {
            content: "sdfgb",
            type: "string" 
        }
    ]

}