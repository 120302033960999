export let component = {
    header: [
        {
            content: "HR менеджер по международным стандартам.",
            type: "string"
        }
    ],
    body: [
        {
            content: "Обзор HRM платформы PeopleForce - лучший функционал и лучший дизайн 🔥HRM прожарка🔥",
            type: "string"
        },
        {
            content: "Обзор HRM платформы PeopleForce - лучший функционал и лучший дизайн 🔥HRM potushenie🔥",
            type: "string"
        }
    ]
}
