import React from "react";
import styles from "./index.module.css"
import logo from "../../assets/images/icons/LogoSSD.svg"
import {useRef, useState, useEffect, useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";
const LOGIN_URL = '/auth'

const Login = ({setSuccess}) =>{
    const { setAuth } = useContext(AuthContext)
    const userRef = useRef();
    const errRef = useRef();

    const [user,setUser] = useState('')
    const [pwd,setPwd] = useState('')
    const [errMsg,setErrMsg] = useState('')


    useEffect(()=>{
        userRef.current.focus();
    }, [])

    useEffect(()=>{
        setErrMsg('')
    }, [user, pwd])

    const handleSubmit = async (e) =>{
        e.preventDefault();

        setAuth({user, pwd})
        setUser('');
        setPwd('');
        setSuccess(true);

        // try {
        //     const response = await axios.post(
        //             LOGIN_URL,
        //             JSON.stringify({user,pwd}),
        //             {
        //                 headers: {'Content-Type': 'application/json'},
        //                 withCredentials: true
        //             }
        //         );
        //     console.log(JSON.stringify(response?.data));
        //     const accessToken = response?.data?.accessToken;
        //     const roles = response?.data?.roles;
        //     setAuth({user, pwd, roles, accessToken})
        //     setUser('');
        //     setPwd('');
        //     setSuccess(true);
        // }
        // catch (err){
        //     if(!err?.response){
        //         setErrMsg('No Server Response');
        //     }
        //     else if(err.response?.status === 400){
        //         setErrMsg('Missing userName or Password')
        //     }
        //     else if(err.response?.status === 401){
        //         setErrMsg('Unauthorized')
        //     }
        //     else {
        //         setErrMsg('Login Fail')
        //     }
        //     errRef.current.focus();
        // }

    }

    return(
        <div className={styles.login}>
            <div className={styles.login_top}>
                <img src={logo} alt='logo'/>
            </div>
            <div className={styles.login_form}>
                <h2 className={styles.login_title}>Вход в аккаунт</h2>
                <form
                    onSubmit={handleSubmit}
                    className={styles.login_form_box}
                >
                    <label className={styles.login_label}>
                        <span>Логин или email</span>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                        />
                    </label>
                    <label className={styles.login_label}>
                        <span>Пароль</span>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />
                    </label>
                    <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'></p>
                    <div className={styles.login_password}>
                        <label className={styles.checkbox_label}>
                            <input type="checkbox"/>
                            <span className={styles.checkmark}></span>
                            Запомнить меня
                        </label>
                        <p className={styles.forgot_password}> Забыли пароль?</p>
                    </div>
                    <button className={styles.login_submit}>Вход в аккаунт</button>
                </form>
            </div>
        </div>
    )
}
export default Login;