import MoreBtn from "components/MoreBtn";
import Table from "../../../../components/Table";
import style from "../../index.module.css"
import more_icon from "assets/images/icons/icon-more-two.svg"
const SubProjects = () => {
    let data = {
        header: [
            {
                content: "№",
                type: "string"
            },
            {
                content: "Название проекта",
                type: "string"
            },
            {
                content: "Участники",
                type: "string"
            },
            {
                content: "Процент",
                type: "string"
            },
            {
                content: "Дата старта",
                type: "string"
            },
            {
                content: "Дата окончания",
                type: "string"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }
        ],
        body: [
            [
                {
                    content: "1",
                    type: "string"
                },
                {
                    content: "Дата окончания",
                    type: "string"
                },
                {
                    content: "Дата окончания",
                    type: "string"
                },
                {
                    content: "65%",
                    type: "string"
                },
                {
                    content: "2023.03.15",
                    type: "string"
                },
                {
                    content: "2023.05.15",
                    type: "string"
                },
                {
                    content: <MoreBtn />,
                    type: "html"
                }
            ]
        ]
    }
    return (
        <div className={`${style.tabel_wrapper} p_0 mt_24`}>
            <Table data={data} />
        </div>
    )
}

export default SubProjects;