import React from "react";
import style from "../../index.module.css"
import person4 from "../../../../assets/images/img-person-four.png";
import more_icon3 from "../../../../assets/images/icons/icon-more-three.svg"
const PositionUpgrade = () =>{
    return (
        <div>
            <div id="sectionThree" className={style.section_card}>
                    <div className={style.cards_wrapper}>
                        <div className={style.card}>
                            <div className={style.card_head}>
                                <div className={style.head_left}>
                                    <img
                                        src={person4}
                                        alt="icon user"
                                    />
                                    <div className={style.texts}>
                                        <p>Бобурмирзаев Лутфулла</p>
                                        <span>HR менеджер</span>
                                    </div>
                                </div>

                                <label className={style.more_settings}>
                                    <input name="more" type="checkbox" hidden id="more-btn" />
                                    <img
                                        src={more_icon3}
                                        alt="icon-more"
                                    />
                                    <div className={style.more_card}>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.3667 2.51663L6.80002 9.0833C6.55002 9.3333 6.30002 9.82497 6.25002 10.1833L5.89169 12.6916C5.75835 13.6 6.40002 14.2333 7.30835 14.1083L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.6333C18.6167 5.49997 19.15 4.1833 17.4834 2.51663C15.8167 0.849966 14.5 1.3833 13.3667 2.51663Z"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.425 3.45825C12.9833 5.44992 14.5417 7.00825 16.5417 7.57492"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Изменить
                                        </button>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.0835 4.14163L7.26683 3.04996C7.40016 2.25829 7.50016 1.66663 8.9085 1.66663H11.0918C12.5002 1.66663 12.6085 2.29163 12.7335 3.05829L12.9168 4.14163"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.6084 13.75H11.3834"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.9165 10.4166H12.0832"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Удалить
                                        </button>
                                    </div>
                                </label>
                            </div>
                            <div className={style.card_body}>
                                <div className={style.texts}>
                                    <span>Год приема на работу:</span>
                                    <p>2019 год</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Опыт работы:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Профессия:</span>
                                    <p>HR менеджер</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Текущий должность:</span>
                                    <p>HR менеджер</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card}>
                            <div className={style.card_head}>
                                <div className={style.head_left}>
                                    <img
                                        src={person4}
                                        alt="icon user"
                                    />
                                    <div className={style.texts}>
                                        <p>Бобурмирзаев Лутфулла</p>
                                        <span>HR менеджер</span>
                                    </div>
                                </div>

                                <label className={style.more_settings}>
                                    <input name="more" type="checkbox" hidden id="more-btn" />
                                    <img
                                        src={more_icon3}
                                        alt="icon-more"
                                    />
                                    <div className={style.more_card}>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.3667 2.51663L6.80002 9.0833C6.55002 9.3333 6.30002 9.82497 6.25002 10.1833L5.89169 12.6916C5.75835 13.6 6.40002 14.2333 7.30835 14.1083L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.6333C18.6167 5.49997 19.15 4.1833 17.4834 2.51663C15.8167 0.849966 14.5 1.3833 13.3667 2.51663Z"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.425 3.45825C12.9833 5.44992 14.5417 7.00825 16.5417 7.57492"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Изменить
                                        </button>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.0835 4.14163L7.26683 3.04996C7.40016 2.25829 7.50016 1.66663 8.9085 1.66663H11.0918C12.5002 1.66663 12.6085 2.29163 12.7335 3.05829L12.9168 4.14163"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.6084 13.75H11.3834"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.9165 10.4166H12.0832"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Удалить
                                        </button>
                                    </div>
                                </label>
                            </div>
                            <div className={style.card_body}>
                                <div className={style.texts}>
                                    <span>Год приема на работу:</span>
                                    <p>2019 год</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Опыт работы:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Профессия:</span>
                                    <p>HR менеджер</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Текущий должность:</span>
                                    <p>HR менеджер</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card}>
                            <div className={style.card_head}>
                                <div className={style.head_left}>
                                    <img
                                        src={person4}
                                        alt="icon user"
                                    />
                                    <div className={style.texts}>
                                        <p>Бобурмирзаев Лутфулла</p>
                                        <span>HR менеджер</span>
                                    </div>
                                </div>

                                <label className={style.more_settings}>
                                    <input name="more" type="checkbox" hidden id="more-btn" />
                                    <img
                                        src={more_icon3}
                                        alt="icon-more"
                                    />
                                    <div className={style.more_card}>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.3667 2.51663L6.80002 9.0833C6.55002 9.3333 6.30002 9.82497 6.25002 10.1833L5.89169 12.6916C5.75835 13.6 6.40002 14.2333 7.30835 14.1083L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.6333C18.6167 5.49997 19.15 4.1833 17.4834 2.51663C15.8167 0.849966 14.5 1.3833 13.3667 2.51663Z"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.425 3.45825C12.9833 5.44992 14.5417 7.00825 16.5417 7.57492"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Изменить
                                        </button>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.0835 4.14163L7.26683 3.04996C7.40016 2.25829 7.50016 1.66663 8.9085 1.66663H11.0918C12.5002 1.66663 12.6085 2.29163 12.7335 3.05829L12.9168 4.14163"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.6084 13.75H11.3834"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.9165 10.4166H12.0832"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Удалить
                                        </button>
                                    </div>
                                </label>
                            </div>
                            <div className={style.card_body}>
                                <div className={style.texts}>
                                    <span>Год приема на работу:</span>
                                    <p>2019 год</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Опыт работы:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Профессия:</span>
                                    <p>HR менеджер</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Текущий должность:</span>
                                    <p>HR менеджер</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card}>
                            <div className={style.card_head}>
                                <div className={style.head_left}>
                                    <img
                                        src={person4}
                                        alt="icon user"
                                    />
                                    <div className={style.texts}>
                                        <p>Бобурмирзаев Лутфулла</p>
                                        <span>HR менеджер</span>
                                    </div>
                                </div>

                                <label className={style.more_settings}>
                                    <input name="more" type="checkbox" hidden id="more-btn" />
                                    <img
                                        src={more_icon3}
                                        alt="icon-more"
                                    />
                                    <div className={style.more_card}>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.3667 2.51663L6.80002 9.0833C6.55002 9.3333 6.30002 9.82497 6.25002 10.1833L5.89169 12.6916C5.75835 13.6 6.40002 14.2333 7.30835 14.1083L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.6333C18.6167 5.49997 19.15 4.1833 17.4834 2.51663C15.8167 0.849966 14.5 1.3833 13.3667 2.51663Z"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.425 3.45825C12.9833 5.44992 14.5417 7.00825 16.5417 7.57492"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Изменить
                                        </button>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.0835 4.14163L7.26683 3.04996C7.40016 2.25829 7.50016 1.66663 8.9085 1.66663H11.0918C12.5002 1.66663 12.6085 2.29163 12.7335 3.05829L12.9168 4.14163"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.6084 13.75H11.3834"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.9165 10.4166H12.0832"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Удалить
                                        </button>
                                    </div>
                                </label>
                            </div>
                            <div className={style.card_body}>
                                <div className={style.texts}>
                                    <span>Год приема на работу:</span>
                                    <p>2019 год</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Опыт работы:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Профессия:</span>
                                    <p>HR менеджер</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Текущий должность:</span>
                                    <p>HR менеджер</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card}>
                            <div className={style.card_head}>
                                <div className={style.head_left}>
                                    <img
                                        src={person4}
                                        alt="icon user"
                                    />
                                    <div className={style.texts}>
                                        <p>Бобурмирзаев Лутфулла</p>
                                        <span>HR менеджер</span>
                                    </div>
                                </div>

                                <label className={style.more_settings}>
                                    <input name="more" type="checkbox" hidden id="more-btn" />
                                    <img
                                        src={more_icon3}
                                        alt="icon-more"
                                    />
                                    <div className={style.more_card}>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.3667 2.51663L6.80002 9.0833C6.55002 9.3333 6.30002 9.82497 6.25002 10.1833L5.89169 12.6916C5.75835 13.6 6.40002 14.2333 7.30835 14.1083L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.6333C18.6167 5.49997 19.15 4.1833 17.4834 2.51663C15.8167 0.849966 14.5 1.3833 13.3667 2.51663Z"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.425 3.45825C12.9833 5.44992 14.5417 7.00825 16.5417 7.57492"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Изменить
                                        </button>
                                        <button className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.0835 4.14163L7.26683 3.04996C7.40016 2.25829 7.50016 1.66663 8.9085 1.66663H11.0918C12.5002 1.66663 12.6085 2.29163 12.7335 3.05829L12.9168 4.14163"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.6084 13.75H11.3834"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.9165 10.4166H12.0832"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Удалить
                                        </button>
                                    </div>
                                </label>
                            </div>
                            <div className={style.card_body}>
                                <div className={style.texts}>
                                    <span>Год приема на работу:</span>
                                    <p>2019 год</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Опыт работы:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Профессия:</span>
                                    <p>HR менеджер</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Текущий должность:</span>
                                    <p>HR менеджер</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card}>
                            <div className={style.card_head}>
                                <div className={style.head_left}>
                                    <img
                                        src={person4}
                                        alt="icon user"
                                    />
                                    <div className={style.texts}>
                                        <p>Бобурмирзаев Лутфулла</p>
                                        <span>HR менеджер</span>
                                    </div>
                                </div>

                                <label className={style.more_settings}>
                                    <input name="more" type="checkbox" hidden id="more-btn" />
                                    <img
                                        src={more_icon3}
                                        alt="icon-more"
                                    />
                                    <div className={style.more_card}>
                                        <label for="more-btn" className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.16669 1.66675H7.50002C3.33335 1.66675 1.66669 3.33341 1.66669 7.50008V12.5001C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5001V10.8334"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.3667 2.51663L6.80002 9.0833C6.55002 9.3333 6.30002 9.82497 6.25002 10.1833L5.89169 12.6916C5.75835 13.6 6.40002 14.2333 7.30835 14.1083L9.81669 13.75C10.1667 13.7 10.6584 13.45 10.9167 13.2L17.4834 6.6333C18.6167 5.49997 19.15 4.1833 17.4834 2.51663C15.8167 0.849966 14.5 1.3833 13.3667 2.51663Z"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.425 3.45825C12.9833 5.44992 14.5417 7.00825 16.5417 7.57492"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Изменить
                                        </label>
                                        <label for="more-btn" className={style.more_item}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.0835 4.14163L7.26683 3.04996C7.40016 2.25829 7.50016 1.66663 8.9085 1.66663H11.0918C12.5002 1.66663 12.6085 2.29163 12.7335 3.05829L12.9168 4.14163"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.6084 13.75H11.3834"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.9165 10.4166H12.0832"
                                                    stroke="#A6B1BB"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Удалить
                                        </label>
                                    </div>
                                </label>
                            </div>
                            <div className={style.card_body}>
                                <div className={style.texts}>
                                    <span>Год приема на работу:</span>
                                    <p>2019 год</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Опыт работы:</span>
                                    <p>4 года</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Профессия:</span>
                                    <p>HR менеджер</p>
                                </div>
                                <div className={style.texts}>
                                    <span>Текущий должность:</span>
                                    <p>HR менеджер</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default PositionUpgrade;