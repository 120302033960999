import EducationDropDown from "../../components/EducationDropDown";
import style from "./index.module.css"
const Education = () => {
  let data = {
    header: [
      {
        content: "HR менеджер по международным стандартам.",
        type: "string"
      }
    ],
    body: [
      {
        content: "Обзор HRM платформы PeopleForce - лучший функционал и лучший дизайн 🔥HRM прожарка🔥",
        type: "string"
      },
      {
        content: "Обзор HRM платформы PeopleForce - лучший функционал и лучший дизайн 🔥HRM potushenie🔥",
        type: "string"
      }
    ]

  }
  return (
    <div className={style.wrapper}>
      <div className={style.section_head}>
        <p className={style.title}>Обучение</p>
      </div>

      <div className={style.tabel_wrapper}>
        <div className={style.accordions}>
          <div className={style.box}>
            <EducationDropDown data={data} />
          </div>

        </div>
      </div>
    </div>
  )
}

export default Education;
