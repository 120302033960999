import style from "../../index.module.css"
import Table from "../../../../components/Table"
const Access = () => {
    let data = {
        header: [
            {
                content: "№",
                type: "string"
            },
            {
                content: "Название проекта",
                type: "string"
            },
            {
                content: "Участники",
                type: "string"
            },
            {
                content: "Процент",
                type: "string"
            },
            {
                content: "Дата старта",
                type: "string"
            },
            {
                content: "Дата окончания",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            }
        ],
        body: [
            [{
                content: "sdfgb",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            },
            {
                content: "s",
                type: "string"
            }]
        ]
    }
    return (
        <div className={`${style.tabel_wrapper} p_0 mt_24`}>
            <Table data={data} />
        </div>
    )
}

export default Access;