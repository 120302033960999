import style from "./index.module.css"
import add_icon from "../../assets/images/icons/icon-add.svg"
import { Tabs } from "../../components/Tabs";
import SubProjects from "./subProjects/Projects";
import closeIcon from 'assets/images/icons/close-square.svg'
import Access from "./subProjects/Access";
import { useState } from "react";
const Projects = () => {
    const [status, setStatus] = useState(false)
    const data = [
        {
            label: "Проекты",
            key: 0,
            children: <SubProjects />
        },
        {
            label: "Доступы",
            key: 1,
            children: <Access />
        }
    ]
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Проекты</p>
                <button onClick={() => setStatus(true)} className={style.add_btn}>
                    <img src={add_icon} alt="icon add" />
                    Добавить проект
                </button>
            </div>

            <Tabs data={data} />


            <div className={status ? 'modal' : 'modal_hiden_bg'}>
                <div className={status ? 'modal_content' : 'modal_hiden'}>
                    <div className={style.modal_header}>
                        <h3 className={style.modal_title}>Новое событие</h3>
                        <button onClick={() => setStatus(false)} className={style.modal_close}>
                            <img src={closeIcon} alt="closeIcon " />
                        </button>
                    </div>
                    <div className={style.modal_body}>
                        <form className={style.modal_form}>
                            <label className={style.form_label_max}>
                                Название проекта
                                <input className={style.form_input} type="text" />
                            </label>

                            <label className={style.form_label}>
                                Дата cтарта
                                <input className={style.form_input} type="date" placeholder="ДД.ММ.ГГГГ" />
                            </label>
                            <label className={style.form_label}>
                                Дата окончания
                                <input className={style.form_input} type="date" placeholder="ДД.ММ.ГГГГ" />
                            </label>
                            <label className={style.form_label_max}>
                                Участники
                                <select className={style.form_select}>
                                    <option value="meeting room">Meeting room</option>
                                    <option selected value="mini meeting room">Mini meeting room</option>
                                </select>
                            </label>

                            <label className={style.form_label_max}>
                                Описание
                                <textarea className={style.form_textarea} />
                            </label>
                        </form>
                    </div>
                    <div className={style.modal_footer}>
                        <button onClick={() => setStatus(false)} type={"submit"} className={style.save_btn}>Сохранить</button>
                        <button className={style.cancel_btn}>Отмена</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects;
