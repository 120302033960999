import { ReactComponent as ControlAccessIcon } from '../../assets/images/icons/ControlAccessIcon.svg';
import { ReactComponent as ControlIcon } from '../../assets/images/icons/control_icon.svg';
import { ReactComponent as EmployeeIcon } from '../../assets/images/icons/employees_icon.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/images/icons/projects_icon.svg';
import { ReactComponent as EventIcon } from '../../assets/images/icons/events_icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar_icon.svg';
import { ReactComponent as RequestsIcon } from '../../assets/images/icons/requests_icon.svg';
import { ReactComponent as EducationIcon } from '../../assets/images/icons/education_icon.svg';
import { ReactComponent as OffersIcon } from '../../assets/images/icons/offers_icon.svg';
import { ReactComponent as VacanciesIcon } from '../../assets/images/icons/vacancies_icon.svg';
import { ReactComponent as AboutUsIcon } from '../../assets/images/icons/iconAboutUs.svg';
import Dashboard from '../../pages/Dashboard';
import Employee from '../../pages/Employee';
import Projects from '../../pages/Projects';
import Events from '../../pages/Events';
import Vacancies from '../../pages/Vacancies';
import Advice from '../../pages/Advices';
import AboutUs from '../../pages/AboutUs';
import Requests from '../../pages/Requests';
import Education from '../../pages/Education';
import ControlAccess from '../../pages/ControlAccess';
import Options from "../../pages/Options"
import Calendar from "../../pages/Calendar";

export const PathRoutes = {
    control: "/",
    employees: "/employee",
    projects: "/projects",
    events: "/event",
    calendar: "/calendar",
    options: "/options",
    vacancies: "/vacancies",
    requests: "/requests",
    education: "/education",
    offers: "/offers",
    aboutus: "/aboutus",
    access: "/accesscontrol"
}


export const RouteConfig = [
    {
        text: "Управление",
        path: PathRoutes.control,
        element: <Dashboard />,
        svgElem: <ControlIcon />,
    },
    {
        text: "Cотрудники",
        path: PathRoutes.employees,
        element: <Employee />,
        svgElem: <EmployeeIcon />,
    },
    {
        text: "Проекты",
        path: PathRoutes.projects,
        element: <Projects />,
        svgElem: <ProjectsIcon />,
    },
    {
        text: "Событие",
        path: PathRoutes.events,
        element: <Events />,
        svgElem: <EventIcon />,
    },
    {
        text: "Календарь",
        path: PathRoutes.calendar,
        element: <Calendar />,
        svgElem: <CalendarIcon/>,
    },
    {
        text: "Вакансии",
        path: PathRoutes.vacancies,
        element: <Vacancies />,
        svgElem: <VacanciesIcon />,
    },
    {
        text: "Запросы",
        path: PathRoutes.requests,
        element: <Requests />,
        svgElem: <RequestsIcon />,
    },
    {
        text: "Обучение",
        path: PathRoutes.education,
        element: <Education />,
        svgElem: <EducationIcon />,
    },
    {
        text: "Предложение",
        path: PathRoutes.offers,
        element: <Advice />,
        svgElem: <OffersIcon />,
    },
    {
        text: "О нас",
        path: PathRoutes.aboutus,
        element: <AboutUs />,
        svgElem: <AboutUsIcon />,
    },
    {
        text: "Доступ для управления",
        path: PathRoutes.access,
        element: <ControlAccess />,
        svgElem: <ControlAccessIcon />,
    },
    {
        path: PathRoutes.options,
        element: <Options />,
    }
]
