import React from "react";
import style from "../../index.module.css"
import down_arrow from "assets/images/icons/arrow-down.svg"
import more_icon from "assets/images/icons/icon-more-two.svg"
import person1 from "assets/images/img-person-one.png"
import Table from "components/Table";
import MoreBtn from "components/MoreBtn";

const Health = () => {
    let data = {
        header: [
            {
                content: "Имя сотрудника"
            },
            {
                content: "Оплачиваемый отпуска",
                type: "html"
            },
            {
                content: "Номер телефона"
            },
            {
                content: "Дата"
            },
            {
                content: "Дата выхода"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }
        ],
        body: [
            [
                {
                    content: <div className={style.name}><img src={person1} /> <p>Жасур Туляганов</p></div>,
                    type: "html"
                },
                {
                    content: "Грипп"
                },
                {
                    content: "+998 93 311 11 10"
                },
                {
                    content: "05.11.2022"
                },
                {
                    content: "15.11.2022"
                },
                {
                    content: <MoreBtn />,
                    type: "html"
                }
            ],
            [
                {
                    content: <div className={style.name}><img src={person1} /> <p>Жасур Туляганов</p></div>,
                    type: "html"
                },
                {
                    content: "Грипп"
                },
                {
                    content: "+998 93 311 11 10"
                },
                {
                    content: "05.11.2022"
                },
                {
                    content: "15.11.2022"
                },
                {
                    content: <MoreBtn />,
                    type: "html"
                }
            ]
        ]
    }
    return (
        <div id="sectionTwo" className={style.section_card}>
            <div className={`${style.tabel_wrapper} p_0 mt_24`}>
                <Table data={data} />
            </div>
        </div>
    )
}
export default Health;