import React from "react";
import style from "./index.module.css"
import person1 from "../../assets/images/img-person-one.png"
import food4 from "../../assets/images/food-four.png"
import food3 from "../../assets/images/food-three.png"
import food2 from "../../assets/images/food-two.png"
import food1 from "../../assets/images/food-one.png"
import arrow_left_icon from "../../assets/images/icons/arrow-left.svg"
import arrow_right_icon from "../../assets/images/icons/arrow-right.svg"
import project_icon from "../../assets/images/icons/icon-project.svg"
import inprocess_icon from"../../assets/images/icons/icon-in-progress.svg"
import done_icon from "../../assets/images/icons/icon-done-two.svg"
import message_icon from "../../assets/images/icons/icon-message.svg"
import clock_icon from "../../assets/images/icons/icon-clock.svg"
import more_icon from "../../assets/images/icons/icon-more.svg"
import member1 from "../../assets/images/member-one.png"
import member2 from "../../assets/images/member-two.png"
import member3 from "../../assets/images/member-three.png"
import Chart from "../../components/BarChart";


const Dashboard = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.box_left}>
                <div className={style.top_text}>
                    <p className={style.title}>Добро пожаловать, Бобур Рустамов! 👋</p>
                    <p className={style.subtitle}>
                        Мы будем рады помочь в управлении вашей командой.
                    </p>
                </div>
                <div className={style.cards}>
                    <div className={style.card}>
                        <div className={style.card_head}>
                            <p className={style.title}>Статистика проекта</p>
                            <select className={style.select}>
                                <option>Ежедневно</option>
                                <option>Ежедневно</option>
                                <option>Ежедневно</option>
                            </select>
                        </div>
                        <div className={style.bar_chart}>
                            <Chart/>
                        </div>
                    </div>
                    <div className={style.card}>
                        <p className={style.title}>Обзор задач</p>
                        <div className={style.card_items}>
                            <div className={style.item}>
                                <img src={project_icon} alt="icon-project" />
                                <p className={style.text}>Проекты</p>
                                <span className={style.count}>32</span>
                            </div>
                            <div className={style.item}>
                                <img src={inprocess_icon} alt="icon in progress" />
                                <p className={style.text}>Проекты</p>
                                <span className={style.count}>32</span>
                            </div>
                            <div className={style.item}>
                                <img src={done_icon} alt="icon done" />
                                <p className={style.text}>Проекты</p>
                                <span className={style.count}>32</span>
                            </div>
                        </div>
                        <div className={style.card_footer}>
                            <p className={style.text}>
                                Вовремя <br />
                                Готовая норма
                            </p>

                            <p className={style.count}>90% <span>&#8593; 20%</span></p>
                        </div>
                    </div>
                </div>

                <div className={style.box_left__footer}>
                    <div className={style.footer_head}>
                        <p className={style.title}>Задачи на сегодня</p>
                        <button className={style.more_btn}>Смотреть все</button>
                    </div>
                    <div className={style.card}>
                        <div className={style.card_left}>
                            <div className={style.card_text}>
                                <span className={style.label}>Редизайн Landing Page</span>
                                <p className={style.text}>Пересмотр страницы отзывов</p>
                            </div>
                            <div className={style.card_chips}>
                                <span className={style.chip}>
                                    <img src={message_icon} alt="icon-message" />
                                    4 Комментарий
                                </span>
                                {/* <span className={style.chip}>
                                    <img src={link_icon} alt="icon-link" />
                                    Прикрепление
                                </span> */}
                                <span className={style.chip}>
                                    <img src={clock_icon} alt="icon-clock" />
                                    Осталось 12 часов
                                </span>
                            </div>
                        </div>
                        <div className={style.card_right}>
                            <button>
                                <img src={more_icon} alt="icon more" />
                            </button>

                            <div className={style.members}>
                                <div className={style.img}>
                                    <img src={member3} alt="member" />
                                </div>
                                <div className={style.img}>
                                    <img src={member2} alt="member" />
                                </div>
                                <div className={style.img}>
                                    <img src={member1} alt="member" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={style.card}>
                        <div className={style.card_left}>
                            <div className={style.card_text}>
                                <span className={style.label}>Дизайн мобильных приложений</span>
                                <p className={style.text}>Onboarding страницы</p>
                            </div>
                            <div className={style.card_chips}>
                                <span className={style.chip}>
                                    <img src={message_icon} alt="icon-message" />
                                    4 Комментарий
                                </span>
                                {/* <span className={style.chip}>
                                    <img src={link_icon} alt="icon-link" />
                                    Прикрепление
                                </span> */}
                                <span className={style.chip}>
                                    <img src={clock_icon} alt="icon-clock" />
                                    Осталось 12 часов
                                </span>
                            </div>
                        </div>
                        <div className={style.card_right}>
                            <button>
                                <img src={more_icon} alt="icon more" />
                            </button>

                            <div className={style.members}>
                                <div className={style.img}>
                                    <img src={member1} alt="member" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.box_right}>
          <div className={style.calendar}>
            <div className={style.calendar_head}>
              <p>Февраль</p>
              <div className={style.buttons_wrapper}>
                <button>
                  <img src={arrow_left_icon} alt="arrow-left" />
                </button>
                <button>
                  <img src={arrow_right_icon} alt="arrow-right" />
                </button>
              </div>
            </div>
            <div className={style.dates}>
              <p>
                Пон
                <span className={style.date}>20</span>
              </p>
              <p>
                Вто
                <span className={`${style.date} active`}>21</span>
              </p>
              <p>
                Сре
                <span className={style.date}>22</span>
              </p>
              <p>
                Чет
                <span className={style.date}>23</span>
              </p>
              <p>
                Пят
                <span className={style.date}>24</span>
              </p>
              <p>
                Суб
                <span className={style.date}>25</span>
              </p>
              <p>
                Вос
                <span className={style.date}>26</span>
              </p>
            </div>
          </div>

          <div className={style.foods_wrapper}>
            <p className={style.title}>Сегодняшнее меню</p>
            <div className={style.card}>
              <div className={style.card_left}>
                <img src={food1} alt="food-one" />
                <div className={style.texts}>
                  <p>Бифштекс</p>
                  <span>
                    Бифштекс - это классический рецепт и неизменный фаворит
                    среди мясоедов.
                  </span>
                </div>
              </div>
            </div>
            <div className={style.card}>
              <div className={style.card_left}>
                <img src={food2} alt="food-two" />
                <div className={style.texts}>
                  <p>Куринный суп</p>
                  <span>
                    Бифштекс - это классический рецепт и неизменный фаворит
                    среди мясоедов.
                  </span>
                </div>
              </div>
            </div>
            <div className={style.cards}>
              <div className={style.card_item}>
                <div className={style.card_top}>
                  <img src={food3} alt="food-three" />
                  <div className={style.texts}>
                    <p>Салат оливье</p>
                  </div>
                </div>
                <span>
                  Бифштекс - это классический рецепт и неизменный.
                </span>
              </div>
              <div className={style.card_item}>
                <div className={style.card_top}>
                  <img src={food4} alt="food-four" />
                  <div className={style.texts}>
                    <p>Яблочный суп</p>
                  </div>
                </div>
                <span>
                  Бифштекс - это классический рецепт и неизменный.
                </span>
              </div>
            </div>
          </div>

          <div className={style.brithday_wrapper}>
            <p className={style.title}>Сегодняшние дни рождения</p>
            <div className={style.card}>
              <div className={style.card_left}>
                <img src={person1} alt="food-one" />
                <div className={style.texts}>
                  <p>Rusram Ergashev</p>
                  <span> Frontend разработчик </span>
                </div>
              </div>

              <div className={style.card_right}>
                <span></span>
                <p>32 лет</p>
              </div>
            </div>
            <div className={style.card}>
              <div className={style.card_left}>
                <img src={person1} alt="food-one" />
                <div className={style.texts}>
                  <p>Rusram Ergashev</p>
                  <span> Frontend разработчик </span>
                </div>
              </div>

              <div className={style.card_right}>
                <span></span>
                <p>32 лет</p>
              </div>
            </div>
            <div className={style.card}>
              <div className={style.card_left}>
                <img src={person1} alt="food-one" />
                <div className={style.texts}>
                  <p>Rusram Ergashev</p>
                  <span> Frontend разработчик </span>
                </div>
              </div>

              <div className={style.card_right}>
                <span></span>
                <p>32 лет</p>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
}

export default Dashboard;