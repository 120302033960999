import { useEffect, useState } from "react"
import style from "./index.module.css"

const stats = [
    {
        text: "Одобрено",
        id: 1
    },
    {
        text: "Рассматривается",
        id: 2
    },
    {
        text: "Отказано",
        id: 3
    }
]
const Status = () => {
    const [status, setStatus] = useState(2)

    useEffect(() => {
        const a = stats.map(item => item.id)
        setStatus(stats.map(item => item.text))
    }, [stats])
    return (
        <div className={style.body}>
            {
                status
            }
        </div>
    )
}
export default Status;