import React, { Component } from 'react';
import CalendarDays from "../../components/CalendarDays";
import styles from './index.module.css';
import iconLeft from '../../assets/images/icons/calendar-icon-left.svg';
import iconRight from '../../assets/images/icons/calendar-icon-right.svg';
import AddEvent from "../../components/AddEvent";


export default class Calendar extends Component {
    constructor() {
        super();

        this.weekdays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
        this.months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

        this.state = {
            currentDay: new Date()
        }
    }

    changeCurrentDay = (day) => {
        this.setState({ currentDay: new Date(day.year, day.month, day.number) });
    }
    nextDay = () => {
        this.setState({ currentDay: new Date(this.state.currentDay.setDate(this.state.currentDay.getDate() + 1)) });
    }

    previousDay = () => {
        this.setState({ currentDay: new Date(this.state.currentDay.setDate(this.state.currentDay.getDate() - 1)) });
    }


    render() {
        return (
            <div className={styles.calendar}>
                <div className={styles.calendar_header}>
                    <div className={styles.header_inner}>
                        <h2 className={styles.calendar_title}>{this.months[this.state.currentDay.getMonth()]} {this.state.currentDay.getFullYear()}</h2>
                        <div className={styles.btns_box}>
                            <button className={styles.calendar_btns} onClick={this.previousDay}>
                                <img src={iconLeft} alt="icon"/>
                            </button>
                            <button className={styles.calendar_btns} onClick={this.nextDay}>
                                <img src={iconRight} alt="icon"/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.header_inner}>
                        <div className={styles.roles}>

                        </div>
                        <AddEvent/>
                    </div>
                </div>
                <div className={styles.calendar_body}>
                    <div className={styles.table_header}>
                        {
                            this.weekdays.map((weekday) => {
                                return <div className={styles.weekday}>
                                    <p className={styles.weekday_text}>{weekday}</p>
                                </div>
                            })
                        }
                    </div>
                    <CalendarDays day={this.state.currentDay} changeCurrentDay={this.changeCurrentDay} />
                </div>
            </div>
        )
    }
}