import { Outlet } from "react-router-dom"
import SideBar from "../SideBar"
import Header from "../Header"

export const OutletTemplate = () => {
    return (
        <div className="main_wrapper">
            <SideBar />
            <div className="main_container">
                <Header />
                <Outlet />
            </div>
        </div>
    )
}