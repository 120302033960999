import style from "../index.module.css"
import keyboard_icon from "../../../assets/images/icons/icon-keyboard.svg"
import keyboard2_icon from "../../../assets/images/icons/icon-kayboard2.svg"
import mobile_icon from "../../../assets/images/icons/icon-mobile.svg"
import magicpen_icon from "../../../assets/images/icons/icon-magicpen.svg"

const SubAboutUs = () => {
    return (
        <div id="sectionOne" className={style.section_card}>
            <p className={style.text}>
                <b> SSD -</b> Рыбатекст используется дизайнерами,
                проектировщиками и фронтендерами, когда нужно быстро заполнить
                макеты или прототипы содержимым. Это тестовый контент, который
                не должен нести никакого смысла, лишь показать наличие самого
                текста или продемонстрировать типографику в деле. <br />
                <br />
                Рыбатекст используется дизайнерами, проектировщиками и
                фронтендерами, когда нужно быстро заполнить макеты или прототипы
                содержимым. Это тестовый контент, который не должен нести
                никакого смысла, лишь показать наличие самого текста или
                продемонстрировать типографику в деле.
            </p>
            <div className={style.cards_wrapper}>
                <p className={style.wrapper_title}>Наши услуги</p>
                <div className={style.cards}>
                    <div className={style.card}>
                        <div className={style.card_head}>
                            <img
                                src={keyboard_icon}
                                alt="icon-keyboard"
                            />
                            <p className={style.card_title}>Веб - разработка</p>
                        </div>
                        <p className={style.card_text}>
                            Рыбатекст используется дизайнерами, проектировщиками и
                            фронтендерами, когда нужно
                        </p>
                    </div>
                    <div className={style.card}>
                        <div className={style.card_head}>
                            <img
                                src={mobile_icon}
                                alt="icon-mobile"
                            />
                            <p className={style.card_title}>Разработка мобильных приложений</p>
                        </div>
                        <p className={style.card_text}>
                            Рыбатекст используется дизайнерами, проектировщиками и
                            фронтендерами, когда нужно
                        </p>
                    </div>
                    <div className={style.card}>
                        <div className={style.card_head}>
                            <img
                                src={magicpen_icon}
                                alt="icon-MagicPen"
                            />
                            <p className={style.card_title}>Веб- и мобильный дизайн</p>
                        </div>
                        <p className={style.card_text}>
                            Рыбатекст используется дизайнерами, проектировщиками и
                            фронтендерами, когда нужно
                        </p>
                    </div>
                    <div className={style.card}>
                        <div className={style.card_head}>
                            <img
                                src={keyboard2_icon}
                                alt="icon-keyboard"
                            />
                            <p className={style.card_title}>Программное обеспечение ERP/CRM</p>
                        </div>
                        <p className={style.card_text}>
                            Рыбатекст используется дизайнерами, проектировщиками и
                            фронтендерами, когда нужно
                        </p>
                    </div>
                </div>
            </div>
            <p className={style.text}>
                Рыбатекст используется дизайнерами, проектировщиками и
                фронтендерами, когда нужно быстро заполнить макеты или прототипы
                содержимым. Это тестовый контент, который не должен нести
                никакого смысла, лишь показать наличие самого текста или
                продемонстрировать типографику в деле.
            </p>
        </div>
    )
}
export default SubAboutUs;