import style from "./index.module.css"
import {ReactComponent as TrashIcon} from "assets/images/icons/icon-trash.svg"
import {ReactComponent as ChangeIcon} from "assets/images/icons/icon-edit.svg"
import more_icon from "assets/images/icons/icon-more-two.svg"
import { useState } from "react"
import { useEffect } from "react"

const more = [
    {
        text: "Детали",
        image: <ChangeIcon />,
        show: false,
        id: 3
    },
    {
        text: "Изменить",
        image: <ChangeIcon />,
        id: 1
    },
    {
        text: "Удалить",
        image: <TrashIcon/>,
        id: 2
    }
]

const modal = [
    {
        icon: "sdfgd",
        title: "sdsvf",
        text: "dsvgfb",
        id: 2
    }
]
const MoreBtn = () => {
    const [showResults, setShowResults] = useState(false)
    function toggle(){
        setShowResults(!showResults)
    }
    return (
        <>
            <div onClick={() => setShowResults(true)} className={style.more}><img src={more_icon} /></div>
            {
                showResults && (
                    <div className={style.more_card}>
                        {
                            more.map(item => (
                                <div className={style.more_item} onClick={toggle}>
                                    {item.image}
                                    {item.text}
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </>
    )
}
export default MoreBtn;