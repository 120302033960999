import style from "./index.module.css"
import closeIcon from 'assets/images/icons/close-square.svg'
import add_icon from "assets/images/icons/icon-add.svg"
import more_icon from "assets/images/icons/icon-more-two.svg"
import person1 from "assets/images/img-person-one.png"
import post_image from "assets/images/icons/icon-gallery-add.svg"
import Table from "components/Table";
import { useEffect, useState } from "react";
import MoreBtn from "components/MoreBtn"
const Employee = () => {
    const [status, setStatus] = useState(false)
    let data = {
        header: [
            {
                content: "Имя сотрудника"
            },
            {
                content: "Имя сотрудника"
            },
            {
                content: "Номер телефона"
            },
            {
                content: "Профессия"
            },
            {
                content: "телеграм"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }
        ],
        body: [
            [{
                content: <div className={style.name}><img src={person1} /> <p>Жасур Туляганов</p></div>,
                type: "html"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: "sdfgb"
            },
            {
                content: <MoreBtn />,
                type: "html"
            }]
        ]
    }
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Сотрудники</p>
                <button onClick={() => setStatus(true)} className={style.add_btn}>
                    <img src={add_icon} alt="icon add" />
                    Добавить сотрудника
                </button>
            </div>

            <div className={style.tabel_wrapper}>
                <Table data={data} />
            </div>
            <div className={status ? 'modal' : 'modal_hiden_bg'}>
                <div className={status ? 'modal_content' : 'modal_hiden'}>
                    <div className={style.modal_header}>
                        <h3 className={style.modal_title}>Новое событие</h3>
                        <button onClick={() => setStatus(false)} className={style.modal_close}>
                            <img src={closeIcon} alt="closeIcon " />
                        </button>
                    </div>
                    <div className={style.modal_body}>
                        <form className={style.modal_form}>
                            <label className={style.form_label_max}>
                                <div className={style.post_image}>
                                    <div className={style.img_gallery}>
                                        <img src={post_image} alt="gallery icon" />
                                    </div>
                                    <div className={style.post_image_txt}>
                                        <p>Допустимые форматы изображения:<br /> .jpeg, .png, .jpg</p>
                                        <label className={style.file_image}>
                                            Загрузить
                                            <input type="file" accept="image/png, image/jpeg" />
                                        </label>
                                    </div>
                                </div>
                            </label>

                            <label className={style.form_label}>
                                Имя
                                <input className={style.form_input} type="text" />
                            </label>
                            <label className={style.form_label}>
                                Фамилия
                                <input className={style.form_input} type="text" />
                            </label>

                            <label className={style.form_label}>
                                Дата приема на работу
                                <input className={style.form_input} type="date" placeholder="ДД.ММ.ГГГГ" />
                            </label>
                            <label className={style.form_label}>
                                Проекты
                                <input className={style.form_input} type="number" placeholder="24" />
                            </label>
                            <label className={style.form_label_max}>
                                Отдел
                                <select className={style.form_select}>
                                    <option value="meeting room">Meeting room</option>
                                    <option selected value="mini meeting room">Mini meeting room</option>
                                </select>
                            </label>

                            <label className={style.form_label_max}>
                                Профессия
                                <input className={style.form_input} type="text" />
                            </label>
                            <label className={style.form_label_max}>
                                Номер телефона
                                <input className={style.form_input} type="phone" />
                            </label>
                        </form>
                    </div>
                    <div className={style.modal_footer}>
                        <button onClick={() => setStatus(false)} type={"submit"} className={style.save_btn}>Сохранить</button>
                        <button className={style.cancel_btn}>Отмена</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Employee;
