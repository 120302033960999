import React, {
    Fragment, Suspense,
} from 'react';
import {
    Navigate, Route, Routes,
} from 'react-router-dom';
import { RouteConfig } from '../../shared/config/RouteConfig';
import { OutletTemplate } from './OutletTemplate';


export const AppRouter = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route element={<OutletTemplate />}>
                    {RouteConfig.map(({ element, path, byDefault }) => (
                        <Fragment key={path}>
                            <Route
                                path={path}
                                element={element}
                            />
                            {
                                byDefault && (
                                    <Route
                                        path="*"
                                        element={<Navigate to={path || '/'} />}
                                    />
                                )
                            }
                        </Fragment>
                    ))}
                </Route>
            </Routes>
        </Suspense>
    )
}