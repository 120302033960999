import React from "react";
import style from "../../index.module.css"
import person5 from "../../../../assets/images/img-person-five.png"
import more_icon3 from "../../../../assets/images/icons/icon-more-three.svg"

const NewEmployees = () => {
    return (
        <div id="sectionOne" className={`${style.section_card} active`}>
            <div className={style.cards_wrapper}>
                <div className={style.card}>
                    <div className={style.card_head}>
                        <div className={style.head_left}>
                            <img
                                src={person5}
                                alt="icon user"
                            />
                            <div className={style.texts}>
                                <p>Санжар Ахмедов</p>
                                <span>HR менеджер</span>
                            </div>
                        </div>

                        <label className={style.more_settings}>
                            <input
                                name="more-two"
                                type="checkbox"
                                hidden
                                id="more-btn"
                            />
                            <img
                                src={more_icon3}
                                alt="icon-more"
                            />
                            
                        </label>
                    </div>

                    <div className={style.card_body}>
                        <div className={style.texts}>
                            <span>Дата приема на работу:</span>
                            <p>20.02.2023</p>
                        </div>
                        <div className={style.texts}>
                            <span>Опыт работы:</span>
                            <p>4 года</p>
                        </div>
                        <div className={style.texts}>
                            <span>Отдел:</span>
                            <p>HR менеджер</p>
                        </div>
                        <div className={style.texts}>
                            <span>Профессия:</span>
                            <p>HR менеджер</p>
                        </div>
                        <div className={style.texts}>
                            <span>Номер телефона:</span>
                            <p>+998 95 313 13 00</p>
                        </div>
                    </div>
                </div>
                <div className={style.card}>
                    <div className={style.card_head}>
                        <div className={style.head_left}>
                            <img
                                src={person5}
                                alt="icon user"
                            />
                            <div className={style.texts}>
                                <p>Санжар Ахмедов</p>
                                <span>HR менеджер</span>
                            </div>
                        </div>

                        <label className={style.more_settings}>
                            <input
                                name="more-two"
                                type="checkbox"
                                hidden
                                id="more-btn"
                            />
                            <img
                                src={more_icon3}
                                alt="icon-more"
                            />
                        </label>
                    </div>

                    <div className={style.card_body}>
                        <div className={style.texts}>
                            <span>Дата приема на работу:</span>
                            <p>20.02.2023</p>
                        </div>
                        <div className={style.texts}>
                            <span>Опыт работы:</span>
                            <p>4 года</p>
                        </div>
                        <div className={style.texts}>
                            <span>Отдел:</span>
                            <p>HR менеджер</p>
                        </div>
                        <div className={style.texts}>
                            <span>Профессия:</span>
                            <p>HR менеджер</p>
                        </div>
                        <div className={style.texts}>
                            <span>Номер телефона:</span>
                            <p>+998 95 313 13 00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewEmployees;
