import { useEffect, useMemo, useRef, useState } from "react"
import moment from "moment/moment"
import "moment/locale/ru"
import style from "./index.module.css"
import back_arrow from "../../assets/images/icons/pagination-arrow-left.svg"
import next_arrow from "../../assets/images/icons/pagination-arrow-right.svg"
import search_icon from "../../assets/images/icons/icon-search.svg"
import person from "../../assets/images/img-person-five.png"

const test = [
    {
        userInfo: {
            text: "some man",
            imgSrc: '',
            job: 'PM'
        },
        color: '#FFD232',
        start: '2023.03.1',
        end: '2023.03.3',
    },
    {
        userInfo: {
            text: "some girl",
            imgSrc: '',
            job: 'PM'
        },
        color: '#F35D48',
        start: '2023.03.1',
        end: '2023.04.1'
    },
    {
        userInfo: {
            text: "some girl",
            imgSrc: '',
            job: 'PM'
        },
        color: '#4882F3',
        start: '2023.03.4',
        end: '2023.03.10'
    },
    {
        userInfo: {
            text: "some girl",
            imgSrc: '',
            job: 'PM'
        },
        color: '#92D839',
        start: '2023.02.1',
        end: '2023.02.10'
    }
]
const duration_types = [
    {
        title: "Неделя",
        id: 1
    },
    {
        title: "Месяц",
        id: 2
    },
    {
        title: "Квартал 1",
        id: 3
    },
    {
        title: "Квартал 2",
        id: 4
    },
    {
        title: "Квартал 3",
        id: 5
    },
    {
        title: "Квартал 4",
        id: 6
    }
]


const TimeLine = () => {

    const [activeD, setActiveD] = useState(1)
    const cellWidth = useRef();
    const [width, setWidth] = useState(2)
    const [calendarData, setCalendarData] = useState({
        calendarStart: '2023.03.01',
        calendarType: 2
    })

    //width for table <th> & <td>
    useEffect(() => {
        if (cellWidth.current) {
            setWidth(cellWidth.current.offsetWidth);
        }
    }, [cellWidth, calendarData.calendarType])

    //current month days
    const month = useMemo(() => {
        const month = moment(calendarData.calendarStart).month()
        const monthArr = Array.from({ length: moment(month).daysInMonth() }, (x, i) => moment().startOf('month').add(i, 'days').format('D dd'))
        return monthArr;
    }, [calendarData])

    //current week
    const week = useMemo(() => {
        const currentDate = moment(calendarData.calendarStart);
        const weekStart = currentDate.clone().startOf('isoWeek');
        const days = [];

        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days').format("dddd, D"));
        }
        return days
        console.log("week", days);
    }, [calendarData])

    //current month
    const currentMonth = useMemo(() => {
        moment.locale('ru')
        const monthTest = moment(calendarData.calendarStart).month();
        const ruCurrentM = moment().month(monthTest).format("MMMM")
        const currentY = moment().year()
        return (ruCurrentM + " " + currentY);
    }, [calendarData])

    const TimelineDurationPagination = () => {
        const a = duration_types.map(item => item.id)
        if (calendarData.calendarType === 2) {
            return month.map((item, index) => {
                console.log(index === 0 && cellWidth);
                return (
                    <>
                        {
                            <th className={style.table_month} ref={index === 0 ? cellWidth : null}>{item.split(' ').map(_item => (<><span>{_item}</span><br /></>))}</th>
                        }
                    </>

                )
            })
        } else if (calendarData.calendarType === 1) {
            return week.map((item1, index) => {
                console.log(index === 0 && cellWidth);
                return (
                    <>
                        {
                            <th className={style.table_week} ref={index === 0 ? cellWidth : null}>{item1}</th>
                        }
                    </>

                )
            })
        } else if (activeD.id === 3) {
            //quater1
        } else if (activeD.id === 4) {
            //quater2
        } else if (activeD.id === 5) {
            //quater3
        } else if (activeD.id === 6) {
            //quater4
        }
    }

    const some = {
        calendarStart: '2023.03.01',
        calendarType: 'week'
    }

    const sdf = useMemo(() => {
        const keys = [
            {
                calendarKey: "week",
                getObj: {
                    methodName: "weekday",
                    functionArgument: undefined,
                    addValue: 1
                }
            },
            {
                calendarKey: "month",
                getObj: {
                    methodName: "get",
                    functionArgument: "date",
                    addValue: 0
                }
            },
        ]
        console.log(keys[calendarData.calendarType - 1])
        const {calendarKey, getObj} = keys[calendarData.calendarType - 1];
        const calendarStart = moment(calendarData.calendarStart);
        const calendarEnd = moment(calendarData.calendarStart).endOf(calendarKey);
        console.log(calendarStart)
        console.log(calendarEnd)

        return test.map((item) => {
            // debugger
            let startDate = moment(item.start);
            let endDate = moment(item.end);
            console.log(calendarStart[getObj.methodName](getObj.functionArgument))
            let start = calendarStart[getObj.methodName](getObj.functionArgument) + getObj.addValue;
            let end = calendarEnd[getObj.methodName](getObj.functionArgument) + getObj.addValue;

            // if(calendarKey === "week") {
            //
            // }
            if(startDate.isBetween(calendarStart, calendarEnd) || startDate.isSame(calendarStart)) {
                start = startDate[getObj.methodName](getObj.functionArgument) + getObj.addValue;
            }else {
                start = null;
            }

            if(endDate.isBetween(calendarStart, calendarEnd) || endDate.isSame(calendarEnd)) {
                end = endDate[getObj.methodName](getObj.functionArgument) + getObj.addValue;
            }else if (!start) {
                end = null;
            }
            // if ((startDate.isBefore(calendarStart) && endDate.isBefore(calendarEnd)) || (startDate.isAfter(calendarStart) && endDate.isAfter(calendarEnd))) {
            //     start = null;
            //     end = null;
            // }
            // else if(startDate.isBefore(calendarStart)) {
            //     console.log("ONE")
            //     start = 1
            // }
            console.log(endDate.weekday())

            // if(endDate.isBetween(calendarStart, calendarEnd) || endDate.isSame(calendarEnd)) {
            //     end = endDate.weekday() + 1
            // }

            // if(!startDate.isSameOrBefore(calendarStart) && !endDate.isSameOrAfter(calendarEnd)) {
            //     start = null;
            //     end = null;
            // }

            // else if(endDate.isAfter(calendarStart)) {
            //     end = calendarEnd.get('date');
            // }

            return { start, end, color: item.color }
        })
    }, [test, calendarData])

    console.log(sdf)

    // const as = useMemo(() => {
    //     const calendarStart = moment(some.calendarStart);
    //     const calendarEnd = moment(some.calendarStart);
    //
    //
    //     test.map((item) => {
    //
    //     })
    // }, [test, some])

    return (
        <div className={style.timeline}>
            <div className={style.timeline_monipulation}>
                <div className={style.calendar}>
                    <div className={style.part_title}><h4>Сотрудники</h4></div>
                    <div className={style.month}>
                        <h4 className={style.selected_month}>
                            {currentMonth}
                        </h4>
                        <div className={style.month_pagination}>
                            <div className={style.next_prev}>
                                <img src={back_arrow} />
                            </div>
                            <div className={style.next_prev}>
                                <img src={next_arrow} />
                            </div>
                        </div>
                    </div>
                    <div className={style.timeline_duration}>

                        {
                            duration_types.map((item, index) => (
                                <div
                                    key={item.id}
                                    className={`${style.duration} ${item.id === calendarData.calendarType ? style.active : ''}`}
                                    onClick={() => setCalendarData(prev => ({...prev, calendarType: item.id}))}
                                >
                                    {item.title}
                                </div>
                            ))

                        }
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>
                            <div className={style.search}>
                                <img src={search_icon} alt="search icon" />
                                <input type="search" placeholder="Поиск" />
                            </div>
                        </th>
                        {TimelineDurationPagination()}
                        {/* {
                            month.map((item, index) => {
                                console.log(index === 0 && cellWidth);
                                return (
                                    <>
                                        {
                                            <th className={style.table_week} ref={index === 0 ? cellWidth : null}>{item.split(' ').map(_item => (<><span>{_item}</span><br /></>))}</th>
                                        }
                                    </>

                                )
                            })
                        } */}
                    </tr>
                </thead>
                <tbody>
                    {
                        test.map(item => {
                            return (
                                <tr>
                                    <td>
                                        <div className={style.employee}>
                                            <img src={person} />
                                            <p>
                                                {item.userInfo.text}
                                            </p>
                                        </div>
                                    </td>
                                    {
                                        TimelineDurationPagination().map(() => {
                                            return (
                                                <td></td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
            <div className={style.line}>
                {
                    sdf.map((item, index) => {
                        console.log(width);
                        if(item.end && item.start) {
                            return (
                                <div
                                    className={style.duration_line}
                                    style={{ top: ((index * 53)) + 'px', left: width * (item.start - 1) + "px", width: ((width * (item.end - (item.start - 1))) - 4) + "px", background: item.color }}
                                />
                            )
                        }

                    })
                }

            </div>
        </div>
    )
}
export default TimeLine;
