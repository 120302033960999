import style from "./index.module.css"
import add_btn from "../../assets/images/icons/icon-add.svg"
import SubVacansies from "./subVacancies/Vacancies"
import { Tabs } from "../../components/Tabs"
const Vacancies = () => {
    const data = [
        {
            label: "Вакансии",
            key: 0,
            children: <SubVacansies />
        },
        {
            label: "Кондидаты",
            key: 1,
            children: <SubVacansies />
        }
    ]
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Вакансии</p>
                <button className={style.add_btn} onlick="openPopup()">
                    <img src={add_btn} alt="icon add" />
                    Создать новый
                </button>
            </div>
            <Tabs data={data} />
        </div>
    )
}


export default Vacancies;