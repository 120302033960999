import React, {useState} from "react";
import styles from "./index.module.css"
import addIcon from '../../assets/images/icons/add.png';
import closeIcon from '../../assets/images/icons/close-square.svg'

const AddEvent = () =>{

    const [status, setStatus] = useState(false)
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    return(
        <>
            <button onClick={()=>setStatus(true)} className={styles.add_btn}>
                <img src={addIcon} alt="icon"/>
                Создать новый
            </button>

            <div className={status ? 'modal' : 'modal_hiden_bg'}>
                <div className={status ? 'modal_content' : 'modal_hiden'}>
                    <div className={styles.modal_header}>
                        <h3 className={styles.modal_title}>Новое событие</h3>
                        <button onClick={()=>setStatus(false)} className={styles.modal_close}>
                            <img src={closeIcon} alt="closeIcon "/>
                        </button>
                    </div>
                    <div className={styles.modal_body}>
                        <form className={styles.modal_form}>
                            <label className={styles.form_label}>
                                Дата
                                <input className={styles.form_input} type="date" placeholder="ДД.ММ.ГГГГ"/>
                            </label>
                            <label className={styles.form_label}>
                                Время
                                <input className={styles.form_input} type="time" placeholder="ДД.ММ.ГГГГ"/>
                            </label>
                            <label className={styles.form_label}>
                                Продолжительность
                                <select className={styles.form_select}>
                                    <option value="1">1 ч.</option>
                                    <option selected value="2">2 ч.</option>
                                    <option value="3">3 ч.</option>
                                    <option value="4">4 ч.</option>
                                </select>
                            </label>
                            <label className={styles.form_label}>
                                Комната
                                <select className={styles.form_select}>
                                    <option value="meeting room">Meeting room</option>
                                    <option selected value="mini meeting room">Mini meeting room</option>
                                </select>
                            </label>
                            <label className={styles.form_label_max}>
                                Участники
                                <select className={styles.form_select}>
                                    <option value="">--</option>
                                    <option value="">--</option>
                                </select>
                            </label>
                            <label className={styles.form_label_max}>
                                Описание
                                <input className={styles.form_input} type="text"/>
                            </label>
                            <label className={styles.form_label}>
                                Отдел
                                <select className={styles.form_select}>
                                    <option value="1">HR</option>
                                    <option selected value="2">PHP</option>
                                    <option value="3">Frontend</option>
                                </select>
                            </label>
                            <label className={styles.form_label}>
                                Ответственный
                                <input className={styles.form_input} type="text"/>
                            </label>
                            <label className={styles.form_label_max}>
                                Вид переговоров
                                <select className={styles.form_select}>
                                    <option value="">--</option>
                                    <option value="">--</option>
                                </select>
                            </label>
                        </form>
                    </div>
                    <div className={styles.modal_footer}>
                        <button onClick={()=>setStatus(false)} type={"submit"} className={styles.save_btn}>Сохранить</button>
                        <button className={styles.cancel_btn}>Отмена</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddEvent;
