import React, {useState} from "react"
import style from "./index.module.css"
import arrow_down from "../../assets/images/icons/arrow-down.svg"
import search_icon from "../../assets/images/icons/icon-search.svg"
import search_item_icon from "../../assets/images/icons/icon-search-item.svg"
import iconTrash from "../../assets/images/icons/icon-trash.svg"
import iconSetting from "../../assets/images/icons/icon-setting.svg"
import person1 from "../../assets/images/img-person-one.png"
import person2 from "../../assets/images/img-person-two.png"
import person3 from "../../assets/images/img-person-three.png"
import {PathRoutes} from "../../shared/config/RouteConfig";
import {Link} from "react-router-dom";

const Header = () => {

    const [dropActive, setDropActive] = useState(false)

    return (
        <div className={style.wrapper}>

            <div className={style.navbar}>
                <div className={style.input_search}>
                    <img className={style.icon_search} src={search_icon} alt="icon-search" />
                    <input placeholder="Поиск проекта, задачи, команды и т.д." className={style.input} id={style.searchInput} />
                    <div className={style.search_card} id="searchCard">
                        <div className={style.card_item}>
                            <p className={style.title}>Предложение</p>
                            <p className={style.subtitle}>22 результат от "Rus"</p>
                        </div>
                        <div className={`${style.card_item} mtb_20`}>
                            <p className={style.label}>Проекты</p>
                            <div className={style.item}>
                                <img src={search_item_icon} alt="icon search items" />
                                <div className={style.item_text}>
                                    <p className={style.text}>Russel <b>панель управления</b></p>
                                    <p className={style.subtitle}>Проект завершен</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.card_item}>
                            <p className={style.label}>Сотрудники</p>
                            <div className={style.iamge_wrapper}>
                                <img src={person1} alt="img-one" className={style.img} />
                                <img src={person2} alt="img-two" className={style.img} />
                                <img src={person3} alt="img-three" className={style.img} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.user_info}>
                    <div onClick={()=>setDropActive(true)} className={style.info} id={style.infoBtn}>
                        <div className={style.info_left}>
                            <img className={style.user_img} src={person1} alt="img-one" />
                            <div className={style.texts}>
                                <p className={style.name}>Бобур Рустамов</p>
                                <p className={style.job}>HR менеджер</p>
                            </div>
                        </div>
                        <img src={arrow_down} alt="arrow-down" className={style.icon_arrow} />
                    </div>

                    <div className={dropActive ? 'openDrop' : 'drop_info_items'}>
                        <Link to={PathRoutes.options} className={style.drop_item}>
                            <img src={iconSetting} alt="iconSetting"/>
                            Параметры аккаунта
                        </Link>
                        <a href="#" className={style.drop_item}>
                            <img src={iconTrash} alt="iconTrash"/>
                            Выйти из аккаутна
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
