import React, {useState} from "react";
import styles from "./index.module.css";

const LinkAccount = () => {

    return (
        <div className={styles.link}>
            <div className={styles.link_top}>
                <h2 className={styles.link_title}>Счёт по ссылке</h2>
            </div>
            <div className={styles.link_body}>
                <div className={styles.link_body_part}>
                    <h3 className={styles.link_subtitle}>Jira</h3>
                    <div className={styles.link_inner}>
                        <label className={styles.link_label}>
                            Email
                            <input className={styles.link_input} type="email"/>
                        </label>
                        <label className={styles.link_label}>
                            Имя
                            <input className={styles.link_input} type="text"/>
                        </label>
                    </div>
                </div>
                <div className={styles.link_body_part}>
                    <h3 className={styles.link_subtitle}>Git</h3>
                    <div className={styles.link_inner}>
                        <label className={styles.link_label}>
                            Email
                            <input className={styles.link_input} type="email"/>
                        </label>
                        <label className={styles.link_label}>
                            Имя
                            <input className={styles.link_input} type="text"/>
                        </label>
                    </div>
                </div>
                <div className={styles.link_body_part}>
                    <h3 className={styles.link_subtitle}>Почта</h3>
                    <div className={styles.link_inner}>
                        <label className={styles.link_label}>
                            Email
                            <input className={styles.link_input2} type="email"/>
                        </label>
                    </div>
                </div>
            </div>
            <div className={styles.options_bottom}>
                <button className={styles.edit_form}>Отправить</button>
            </div>
        </div>
    )
}

export default LinkAccount;