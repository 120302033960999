import React, {useState} from "react";
import styles from "./index.module.css";
import PersonalData from "../../components/PersonalData";
import LinkAccount from "../../components/LinkAccount";
import Password from "../../components/Password";
import ProfileCircle from "../../assets/images/icons/profile-circle.svg";
import LinkIcon from "../../assets/images/icons/icon-link.svg";
import LockIcon from "../../assets/images/icons/lock.svg"

const Options = () => {

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTab = (a) =>{
       setActiveTab(a)
        console.log(a)
    }

    return (
        <div className={styles.wrapper}>
             <div className={styles.wrapper_left}>
                 <div onClick={()=>handleTab('tab1')} className={activeTab === "tab1" ? "activeTab" : "wrapper_item"}>
                     <img className={styles.options_img} src={ProfileCircle} alt="icon"/>
                     <p>Личные данные</p>
                 </div>
                 <div onClick={()=>handleTab('tab2')} className={activeTab === "tab2" ? "activeTab" : "wrapper_item"}>
                     <img className={styles.options_img} src={LinkIcon} alt="icon"/>
                     <p>Счёт по ссылке</p>
                 </div>
                 <div onClick={()=>handleTab('tab3')} className={activeTab === "tab3" ? "activeTab" : "wrapper_item"}>
                     <img className={styles.options_img} src={LockIcon} alt="icon"/>
                     <p>Пароль</p>
                 </div>
             </div>
             <div className={styles.wrapper_right}>
                 {activeTab === "tab1" ? <PersonalData/> : null }
                 {activeTab === "tab2" ? <LinkAccount/> : null }
                 {activeTab === "tab3" ? <Password/> : null }
             </div>
        </div>
    )
}

export default Options;
