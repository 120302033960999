import Ethics from "./AboutUsSub/Ethics";
import Grades from "./AboutUsSub/Grades";
import Regulations from "./AboutUsSub/Regulations";
import SubAboutUs from "./AboutUsSub/SubAboutUs";
import style from "./index.module.css"
import { Tabs } from "../../components/Tabs";

const AboutUs = () => {
    const data = [
        {
            label: "О нас",
            key: 0,
            children: <SubAboutUs />
        },
        {
            label: "Корпоративная этика",
            key: 1,
            children: <Ethics />
        },
        {
            label: "Регламент",
            key: 2,
            children: <Regulations />
        },
        {
            label: "Грейды",
            key: 3,
            children: <Grades />
        }
    ]
    return (
        <div className={style.wrapper}>
            <p className={style.title}>О нас</p>
            <div className={style.box}>
                <Tabs data={data} className={style.subpages} />
            </div>
        </div>
    )
}

export default AboutUs;