import React, {useState} from "react";
import styles from "./index.module.css";
import eye from "../../assets/images/icons/eye.svg"

const Password = () => {

    return (
        <div className={styles.password}>
            <div className={styles.password_top}>
                <h2 className={styles.password_title}>Пароль</h2>
            </div>
            <div className={styles.password_body}>
                <label className={styles.password_label}>
                    Введите старый пароль
                    <div className={styles.password_input_box}>
                        <input type="password"/>
                        <img src={eye} alt="eye"/>
                    </div>
                </label>
                <label className={styles.password_label}>
                    Введите новый пароль
                    <div className={styles.password_input_box}>
                        <input type="password"/>
                        <img src={eye} alt="eye"/>
                    </div>
                </label>
            </div>
            <div className={styles.options_bottom}>
                <button className={styles.edit_form}>Сохранить</button>
            </div>
        </div>
    )
}

export default Password;
