import style from "../../index.module.css"
import Table from "../../../../components/Table"
import more_icon from "../../../../assets/images/icons/icon-more-two.svg"
const SubVacansies = () => {
    let data = {
        header: [
            {
                content: "№"
            },
            {
                content: "Тип вакансии"
            },
            {
                content: "Режим работы"
            },
            {
                content: "Статус"
            },
            {
                content: "Дата"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }
        ],
        body: [
            [
                {
                    content: "1",
                    type: "number"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: <div><img src={more_icon} /></div>,
                    type: "html"
                }
            ],
            [
                {
                    content: "2",
                    type: "number"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: "sdfgb"
                },
                {
                    content: <div><img src={more_icon} /></div>,
                    type: "html"
                }
            ]
        ]
    }
    return (
        <div className={`${style.tabel_wrapper} p_0 mt_24`}>
            <Table data={data} />
        </div>
    )
}
export default SubVacansies;