import { useMemo, useState } from "react";
import style from "./index.module.css"

export const Tabs = ({ data }) => {
    const [activeTab, setActiveTab] = useState(0);

    const element = useMemo(() => {
        return data.find(item => item.key === activeTab) || {};
    }, [activeTab])
console.log(element, "element");
    return (

        <div className={style.section_body}>
            <div className={style.tab_buttons}>
                {
                    data.map(item => (
                        <button key={item.key} onClick={() => setActiveTab(item.key)} className={`${style.button} ${activeTab === item.key ? style.active : ''}`}>
                            {item.label}
                        </button>
                    ))
                }

                {/* <button onClick={} className={`${style.button} active`}>
            </button> */}
            </div>
            <div>
                {
                    element.children && element.children
                }
            </div>
        </div>
    )
}
