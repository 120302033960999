import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

const data = [
    {
        name: 'Пон',
        pv: 80,
    },
    {
        name: 'Вто',
        pv: 100,
    },
    {
        name: 'Сре',
        pv: 60,
    },
    {
        name: 'Чет',
        pv: 80,
    },
    {
        name: 'Пят',
        pv: 50,
    },
    {
        name: 'Суб',
        pv: 30,
    }
];

const Chart = () => {

    return (
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 10,
                    left: 10,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="pv" fill="#03BDB3" />
            </BarChart>
    );
}

export default Chart;