import React from "react";
import styles from "./index.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import item1 from '../../assets/images/slider/item1.png';
import item2 from '../../assets/images/slider/item2.png';
import item3 from '../../assets/images/slider/item3.png';

const Slider = () =>{
    return(
        <div className={styles.wrapper}>
            <div className={styles.slider_container}>
                <Swiper
                    modules={[Pagination]}
                    style={{width: "100%"}}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={{
                        delay: 500,
                        disableOnInteraction: false
                    }}
                >
                    <SwiperSlide>
                        <div className={styles.slider_imgbox}>
                            <img alt="slider_item" src={item1}/>
                        </div>
                        <div className={styles.slider_body}>
                            <h3 className={styles.body_title}>Удобный интерфейс</h3>
                            <p className={styles.body_text}>Рыбатекст используется дизайнерами, проектировщиками и фронтендерами, когда нужно быстро заполнить макеты или прототипы содержимым.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.slider_imgbox}>
                            <img alt="slider_item" src={item2}/>
                        </div>
                        <div className={styles.slider_body}>
                            <h3 className={styles.body_title}>Поисковая система</h3>
                            <p className={styles.body_text}>Рыбатекст используется дизайнерами, проектировщиками и фронтендерами, когда нужно быстро заполнить макеты или прототипы содержимым.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide> <div className={styles.slider_imgbox}>
                        <img alt="slider_item" src={item3}/>
                    </div>
                        <div className={styles.slider_body}>
                            <h3 className={styles.body_title}>Удобный интерфейс</h3>
                            <p className={styles.body_text}>Рыбатекст используется дизайнерами, проектировщиками и фронтендерами, когда нужно быстро заполнить макеты или прототипы содержимым.</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}
export default Slider;