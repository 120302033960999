import style from "./index.module.css";
import { useState } from "react";
import { component } from "./InnerComponent/DropDownComponent"
import arrow_down from "../../assets/images/icons/arrow-down.svg";
const EducationDropDown = (props) => {
    const { data = component } = props;
    const [active, setActive] = useState(false)
    const handleClick = () => {
        setActive(current => !current);
    };
    function titles(type) {
        const config = {
            header: {
                string: (content) => <p>{content}</p>
            },
            body: {
                html: (content) => <li>{content}</li>
            }
        }

        return config[type]
    }

    const render = (compType, { content, type = "string" }) => {
        let a = titles(compType);
        return a[type](content);
    }
    return (
        <div className={style.accordion}>
            <div className={style.accordion__slot_view}>
                <button className={style.accordion__button} onClick={handleClick}>
                    {
                        data.header.map(item => {
                            console.log(item);
                            return render("header", item);
                        })
                    }
                    <img
                        src={arrow_down}
                        alt="IconArrowDown"
                    />
                </button>
            </div>
            <div className={active ? style.accordion__slot : style.accordion__slot_hidden}>
                <div className={style.accordion__panel}>
                    {
                        data.body.map(item => {
                            console.log(item);
                            return (
                                <ul>
                                    {
                                        item.map(item => {
                                            console.log(item);
                                            return render("body", item);
                                        })
                                    }
                                </ul>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default EducationDropDown;


{/* <li><Link to="https://www.youtube.com/watch?v=FfQioJDOYwo">
    Обзор HRM платформы PeopleForce - лучший функционал и
    лучший дизайн 🔥HRM прожарка🔥
</Link></li> */}
