import React from "react";
import style from "./index.module.css"
import add_icon from "../../assets/images/icons/icon-add.svg"
import more_icon from "../../assets/images/icons/icon-more-two.svg"
import person1 from "../../assets/images/img-person-one.png"
import Table from "../../components/Table";
const Requests = () => {
    let data = {
        header: [
            {
                content: "Запросы"
            },
            {
                content: "Добавил"
            },
            {
                content: "Ответственный"
            },
            {
                content: "Дата"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }
        ],
        body: [
            [{
                content: "Купить одноразовые вилки и ложки"
            },
            {
                content: "Тимур Бекмамбетов"
            },
            {
                content: "Тимур Бекмамбетов"
            },
            {
                content: "05.11.2022"
            },
            {
                content: <div><img src={more_icon} /></div>,
                type: "html"
            }]
        ]
    }
    return (
        <div className={style.wrapper}>
            <div className={style.section_head}>
                <p className={style.title}>Запросы</p>
                <button className={style.add_btn} onclick="handleOpenPopup()">
                    <img src={add_icon} alt="icon add" />
                    Создать новый
                </button>
            </div>

            <div className={style.tabel_wrapper}>
                <Table data={data} />
            </div>
        </div>
    )
}

export default Requests;